import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import Seo from "../components/Seo"
import {
  GridContainer,
  GridItemFullWidth,
  GridItem,
} from "../components/layout/Grid"
import H2Header from "../components/H2Header"
import BlogPostPreview from "../components/BlogPostPreview"

const Blog = () => {
  const {
    allMdx: { nodes: posts },
  } = useStaticQuery(graphql`
    {
      allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
        nodes {
          ...BlogPostPreview
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Blog" />
      <GridContainer>
        <GridItemFullWidth>
          <H2Header>Blog</H2Header>
        </GridItemFullWidth>
        {posts.map(post => (
          <GridItem key={post.id}>
            <BlogPostPreview data={post} />
          </GridItem>
        ))}
      </GridContainer>
    </Layout>
  )
}

export default Blog
